import { derived, writable } from "svelte/store";
import { getFilterFacts } from "../selectors/filter";
import { createActiveDietStore } from "./diet";
/**
 * Creates a custom store for a filter
 * @param allergens - Initial allergens list
 * @param diets - Initial diets list
 * @returns A filter store
 */
export const createFilterStore = (allergens, categories, diets) => {
    const activeAllergens = createActiveDietStore(allergens);
    const activeAllergenCategories = createActiveDietStore(categories);
    const activeDiets = createActiveDietStore(diets);
    const hasAcknowledgedPolicy = writable(false);
    const filter = derived([activeAllergens, activeAllergenCategories, activeDiets, hasAcknowledgedPolicy], ([$allergens, $allergenCategories, $diets, $hasAcknowledgedPolicy]) => {
        const filterFacts = getFilterFacts($allergens, $diets);
        return {
            allergens: $allergens,
            allergenCategories: $allergenCategories,
            diets: $diets,
            hasAcknowledgedPolicy: $hasAcknowledgedPolicy,
            ...filterFacts,
        };
    });
    return {
        subscribe: filter.subscribe,
        allergenCategories: {
            add: activeAllergenCategories.add,
            remove: activeAllergenCategories.remove,
            reset: activeAllergenCategories.reset,
        },
        allergens: {
            add: activeAllergens.add,
            remove: activeAllergens.remove,
            reset: activeAllergens.reset,
        },
        diets: {
            add: activeDiets.add,
            remove: activeDiets.remove,
            reset: activeDiets.reset,
        },
        acknowledgePolicy: () => hasAcknowledgedPolicy.set(true),
    };
};
